.app
    .coownership-council
        &.grid.grid-5
            grid-template-areas: "child1 child1" "child2 child3" "child4 child5"
            grid-template-columns: 1fr 1fr
            grid-template-rows: 1fr 1fr 1fr
            &.parent-graph
                grid-template-rows: auto 1fr 1fr
            @media screen and (min-width: $screen-lg) and (max-width: $screen-xl), screen and (max-width: $screen-md)
                grid-template-areas: "child1" "child2" "child3" "child4" "child5"
                grid-template-columns: 1fr
                grid-template-rows: 1fr 1fr 1fr 1fr 1fr
                &.parent-graph
                    grid-template-rows: auto 1fr 1fr 1fr 1fr
            .card-btn
                min-height: 0

    .table.table-coowners
        td
            vertical-align: top
        .td-label.lots
            max-width: 600px
        a.table-picto
            display: inline-block
            margin: 3px
            text-indent: -9999px
            height: 30px
            width: 30px
            background: url('../../images/pictogrammes/notebook-grey.svg') no-repeat center center
            background-size: contain
            &:hover,
            &:focus,
            &:active
                background-image: url('../../images/pictogrammes/notebook-blue.svg')

    .extended-link
        color: $grey

// .app.pro
//     .table.table-coowners a.table-picto:hover,
//     .table.table-coowners a.table-picto:focus,
//     .table.table-coowners a.table-picto:active
//         background-image: url('../../images/pictogrammes/notebook-blue.svg')
    
// .app.ben
//     .table.table-coowners a.table-picto:hover,
//     .table.table-coowners a.table-picto:focus,
//     .table.table-coowners a.table-picto:active
//         background-image: url('../../images/pictogrammes/notebook-green.svg')
