.app .table
    text-align: center
    margin-bottom: 0
    tbody
        border: 0

    thead tr
        // background-color: transparentize($grey, 0.7)
        background-color: transparentize($blue, 0.7)
        color: $blue
    .tr-colored
        // background-color: transparentize($grey, 0.8)
        background-color: transparentize($blue, 0.8)

    th
        vertical-align: middle
        padding: 10px
        font-weight: $bold
        border: 0
        text-transform: uppercase
    td
        padding: 10px
        border: 0
        vertical-align: middle

        p
            margin-bottom: 0.5em 
        p:last-child
            margin-bottom: 0

        .form-group-check-box
            margin-bottom: 0
            label
                margin-bottom: 0

    .td-align-left
        text-align: left !important
    .td-align-right
        text-align: right !important
    .td-label
        @extend .td-align-left
        word-break: break-word
        min-width: 175px
        @media screen and (max-width: $screen-sm)
            min-width: 145px

    .title,
    .subtitle,
    .total,
    .subtotal
        font-weight: $bold
        color: $blue
    .subtotal,
    .subsubtotal
        background-color: transparentize($light_grey, 0.4)
        color: $blue
    .subsubtotal
        font-weight: $medium

    .highlight
        font-weight: $medium
    .amount
        white-space: nowrap
        text-align: right
    .date
        white-space: nowrap
    .amount-neutral
        color: $grey
    .amount-negative
        color: $red
    .amount-positive
        color: $green

    .table-picto
        height: 25px
        cursor: pointer
    a.table-picto
        display: inline-block
        text-indent: -9999px
        width: 40px

    .table-hidden-rows
        .table-picto.arrow
            transform: rotate(90deg)
            transition: all 0.5s
            &.clicked
                transform: rotate(0deg)
        tr
            background-color: white
            .animated-cell
                transition: height 0.3s
                overflow: hidden
                text-overflow: ellipsis
        .tr-clickable
            cursor: pointer
        .hideable
            border-top: 1px solid white
            td
                background-color: $light_grey
        .hidden
            border-top: 0
            overflow: hidden
            &:after
                content: none
            td
                padding-top: 0
                padding-bottom: 0
                transition: all 0.3s
                .animated-cell
                    height: 0 !important

    &.horizontal
        padding-bottom: 0 !important
        th,
        td
            word-break: normal
            padding: 10px 15px
            line-height: inherit
        th
            text-align: center
            // background-color: transparentize($grey, 0.7)
            background-color: transparentize($blue, 0.7)
            color: $blue
        td
            text-align: left
            width: 100%
        tr:nth-child(odd) td
            // background-color: transparentize($grey, 0.8)
            background-color: transparentize($blue, 0.8)

// .app.pro .table
//     thead tr
//         background-color: transparentize($blue, 0.7)
//         color: $blue
//     .tr-colored
//         background-color: transparentize($blue, 0.8)
//     .title,
//     .subtitle,
//     .total,
//     .subtotal,
//     .subsubtotal,
//         color: $blue

//     &.horizontal
//         tr:nth-child(odd) td
//             background-color: transparentize($blue, 0.8)
//         th
//             background-color: transparentize($blue, 0.7)
//             color: $blue

// .app.ben .table
//     thead tr
//         background-color: transparentize($green, 0.7)
//         color: $green
//     .tr-colored
//         background-color: transparentize($green, 0.8)
//     .title,
//     .subtitle,
//     .total,
//     .subtotal,
//     .subsubtotal,
//         color: $green

//     &.horizontal
//         tr:nth-child(odd) td
//             background-color: transparentize($green, 0.8)
//         th
//             background-color: transparentize($green, 0.7)
//             color: $green

@media screen and (max-width: $screen-xs), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .table
        th,
        td,
        .table-hidden-rows tr td
            padding: 5px

        &.horizontal
            td
                width: auto
