// My Data
.my-data
    &.grid.grid-5
        grid-template-rows: 1fr auto auto auto
        .key-card
            max-height: none
        @media screen and (min-width: ($screen-sm))
            .council-card, .syndic-card
                max-height: 530px

    .documents
        display: block
        text-transform: uppercase
        font-weight: $bold

// My Keys
.app .table.table-keys .lots
    background-color: $light_grey
    border-top: 1px solid white