.app header
    height: $header_height
    display: flex
    flex-direction: row
    align-items: center
    font-size: 23px
    white-space: nowrap
    // background-color: $light_grey
    // color: $grey
    background-color: $blue
    color: white
    a
        height: 100%
        padding: 20px 40px 15px
        // color: $grey
        color: white
        &:hover,
        &:focus,
        &:active,
        &.active
            // color: darken($grey, 20)
            color: darken($blue, 20)

    .menu-top
        display: flex
        margin-left: auto
        height: 100%
        max-width: 100%
    .menu-top a 
        display: inline-block
        text-decoration: none
        color: white
        &:hover,
        &:focus,
        &:active,
        &.active
            text-decoration: none
            // background-color: $grey
            // color: white
            background-color: white
            color: $blue

    .menu-top .user-menu
        position: relative
        max-width: 300px
        min-width: 0
        z-index: 100
        .user-menu-title
            display: inline-block
            position: relative
            height: 100%
            z-index: 1
            padding: 20px 20px 15px
            transition: all 0.3s
            overflow: hidden
            text-overflow: ellipsis
            max-width: 100%
            min-width: 200px
            text-align: center
            // background-color: $light_grey
            // color: $grey
            background-color: $blue
            color: white
            &:hover,
            &:focus,
            &:active
                // background-color: $grey
                // color: white
                background-color: white
                color: $blue
        &:hover,
        &:focus,
        &:active,
        &.active
            .user-menu-title
                // background-color: $grey
                // color: white
                background-color: white
                color: $blue
            .user-menu-hover
                display: block
                opacity: 1
                transform: translateY(0)
                box-shadow: 0 0 5px 0 rgba(0,0,0,0.3)
                white-space: normal
                height: auto
                width: auto
                // background-color: $grey
                background-color: white
            
        .user-menu-hover
            position: absolute
            right: 0
            left: 0
            top: 100%
            height: 0
            width: 0
            z-index: -1
            background-color: transparent
            transition: all 0.3s
            opacity: 0
            transform: translateY(-101%)
            font-size: 18px
            font-weight: $medium
            overflow: hidden
            a
                // color: white
                color: $blue
                padding: 20px 10px 10px
                display: block
                text-align: center
                &:hover,
                &:focus,
                &:active,
                &.active
                    // background-color: darken($grey, 15)
                    background-color: $blue
                    color: white
            ul
                margin: 0
                padding: 0
                li
                    list-style-type: none

    .coownership-link
        height: 100%
        padding: 0
        margin: 0
        font-size: inherit
        font-weight: inherit
        color: inherit
        line-height: inherit
        overflow: hidden
        text-overflow: ellipsis
        a
            display: inline-block
            text-decoration: none
            &:hover,
            &:focus,
            &:active
                // background-color: $grey
                // color: white
                background-color: white
                color: $blue

    .header-text 
        display: inline-block
        padding: 20px 40px 15px
        margin: 0
        font-style: italic
        a
            text-decoration: underline
            padding: 0

// .app.pro header
//     background-color: $blue
//     color: white
//     .coownership-link a,
//     .menu-top a 
//         color: white
//         &:hover,
//         &:focus,
//         &:active,
//         &.active
//             background-color: white
//             color: $blue
//     .menu-top .user-menu
//         .user-menu-title
//             background-color: $blue
//             color: white
//         &:hover,
//         &:focus,
//         &:active,
//         &.active
//             .user-menu-title
//                 background-color: white
//                 color: $blue
//             .user-menu-hover
//                 background-color: white
//         .user-menu-hover a
//             color: $blue
//             &:hover,
//             &:focus,
//             &:active
//                 background-color: $blue
//                 color: white

// .app.ben header
//     background-color: $green
//     color: white
//     .coownership-link a,
//     .menu-top a 
//         color: white
//         &:hover,
//         &:focus,
//         &:active,
//         &.active
//             background-color: white
//             color: $green
//     .menu-top .user-menu
//         .user-menu-title
//             background-color: $green
//             color: white
//         &:hover,
//         &:focus,
//         &:active,
//         &.active
//             .user-menu-title
//                 background-color: white
//                 color: $green
//             .user-menu-hover
//                 background-color: white
//         .user-menu-hover a
//             color: $green
//             &:hover,
//             &:focus,
//             &:active,
//             &.active
//                 background-color: $green
//                 color: white

@media screen and (max-width: $screen-xl)
    .app header .header-text 
        display: none

@media screen and (max-width: $screen-lg)
    .app header
        padding-left: 70px
        a
            padding-left: 20px
            padding-right: 20px 
        .menu-top 
            > a 
                display: none
            .user-menu .user-menu-title
                padding-left: 20px
                padding-right: 20px 

@media screen and (max-width: $screen-sm) 
    .app header .coownership-link
        display: none