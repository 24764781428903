.app .contact
    .download-btn
        margin: 10px 0 30px
    h3
        text-decoration: underline
        margin-bottom: 20px
    blockquote
        margin-bottom: 30px
    label
        width: 120px
    