.app .table.table-balance 
    tbody
        .subtitle
            text-align: center
            td
                padding: 10px
        .amount-negative
            color: $grey
        .amount-positive
            color: $grey
    .total .amount,
    .subtotal .amount
        color: $blue

// .app.pro .table.table-balance 
//     .total .amount,
//     .subtotal .amount,
//         color: $blue

// .app.ben .table.table-balance
//     .total .amount,
//     .subtotal .amount,
//         color: $green