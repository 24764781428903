.disappeared
    display: none !important
    // to hide text : bootstrap class : sr-only

// For PrintJs
iframe
    border: 0

.app
    display: flex
    flex-direction: row
    min-height: 100vh
    font-family: $font-family-base
    color: $grey
    position: relative

    // Link
    a
        transition: all 0.3s
        &.extended-link:before
            content: ''
            position: absolute
            display: block
            left: 0
            right: 0
            top: 0
            bottom: 0
            z-index: 10

    main a
        text-decoration: underline
        color: inherit
        &:hover,
        &:focus,
        &:active
            // color: darken($grey, 20)
            color: $blue
    // &.pro main a
    //     &:hover,
    //     &:focus,
    //     &:active
    //         color: $blue
    // &.ben main a
    //     &:hover,
    //     &:focus,
    //     &:active
    //         color: $green

    main h2 a
        text-decoration: none
        &:hover,
        &:focus,
        &:active
            text-decoration: none

    // Text
    strong, b
        font-weight: 600
        
    .medium
        font-weight: $medium

    blockquote
        font-style: italic
        font-size: 24px

    .font-size-less
        font-size: 14px

    .mention
        font-size: 1rem
        font-style: italic

    // Tools
    select
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        cursor: pointer
        padding-right: 22px !important
        background: url('../images/pictogrammes/arrow-grey.svg') no-repeat right 5px center
        background-size: 12px auto

    .btn-group .divider
        // background-color: $blue
        background-color: $orange
        &::after
            content: ''
            position: absolute
            margin-left: -2px
            top: 6px
            bottom: 6px
            z-index: 2
            background-color: white
            border-radius: 4px
            width: 4px
    // &.pro .btn-group .divider
    //     background-color: $blue
    // &.ben .btn-group .divider
    //     background-color: $green

    .standfirst
        margin-bottom: 15px
        .item-selector
            display: inline-block
            margin-bottom: 15px
            margin-top: 0
            margin-right: 25px
            &:last-child
                margin-right: 0
        .toggle-btn-wrapper
            margin-bottom: 0
    .standfirst.toggle-plus-select
        text-align: center
        .toggle-btn-wrapper
            margin-top: 10px
            margin-bottom: 10px
    .item-selector
        align-self: flex-start
        margin-top: 10px
        label
            padding-right: 5px
            margin: 0
        select
            background-color: $light_grey
            border-radius: 10px
            color: $grey
            border: 0
            padding: 5px 5px 3px

    .standfirst .item-selector-dropdown
        flex-grow: 1
        display: flex
        flex-wrap: wrap
        @media screen and (min-width: $screen-md)
            flex-wrap: nowrap
        label
            margin-bottom: 10px
        @media screen and (min-width: $screen-xl)
            display: flex
            label
                margin-top: 8px
                margin-bottom: 8px

    .dropdown.multi-select
        min-width: 300px
        max-width: 100%
        min-height: 37px
        flex-grow: 1
        border-radius: 10px
        padding: 6px 22px 0px 6px
        cursor: pointer
        background: $light_grey url("../images/pictogrammes/arrow-grey.svg") no-repeat right 5px top 15px
        background-size: 12px auto
        
        .label
            background-color: $grey
            font-size: 18px
            color: white
            &:hover,
            &:focus
                background-color: $red

        .dropdown-toggle
            padding-top: 4px

        .dropdown-menu
            margin-top: 0
            right: 0
            max-height: 400px
            overflow-x: hidden
            // overflow-y: 
            background-color: $light_grey
            padding: 0
            > .item
                padding: 3px 10px 2px 
                cursor: pointer
                &:hover,
                &:focus,
                &:active
                    background-color: #1e90ff
                    color: white
                    
        &:hover .dropdown-menu,
        &.open .dropdown-menu
            display: block

    .label,
    .dropdown.multi-select .label
        display: inline-block
        border-radius: 10px
        padding: 3px 10px 2px
        cursor: pointer
        transition: all .3s
        margin: 0 6px 6px 0
        // background-color: $light_grey
        background-color: $blue
        &:hover,
        &:focus
            background-color: $red
            color: white
    // &.pro .label,
    // &.pro .dropdown.multi-select .label
    //     background-color: $blue
    //     color: white
    //     &:hover,
    //     &:focus
    //         background-color: $red
    // &.ben .label,
    // &.ben .dropdown.multi-select .label
    //     background-color: $green
    //     color: white
    //     &:hover,
    //     &:focus
    //         background-color: $red

    .form.form-multi-dropdown
        width: auto
        display: flex
        flex-grow: 1
        align-items: center
        justify-content: flex-start
        align-items: flex-start
        flex-wrap: wrap
        @media screen and (min-width: 1400px)
            flex-wrap: nowrap
        label
            flex-shrink: 0

    // Log
    .user-log-wrapper
        position: fixed
        z-index: 99
        top: $header_height
        left: $aside_width
        right: 0
        padding: 15px 15px 0
        text-align: center
        max-width: 1170px
        margin-left: auto
        margin-right: auto
        transition: all 0.3s
        .user-log
            display: inline-block
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.3)
            padding: 8px 30px
            border-radius: 10px
            opacity: 1
            transition: all 0.3s
            &.success
                color: #155724
                background-color: #d4edda
                border: 1px solid #c3e6cb
            &.error
                color: #721c24
                background-color: #f8d7da
                border-color: #f5c6cb
        &.hidden .user-log
            opacity: 0
        @media screen and (max-width: $screen-xl)
            left: 0
            max-width: 100%

    &.unscrollable .user-log-wrapper,
    &.not-logged-page .user-log-wrapper
        left: 0
        top: 15px
        z-index: 1000

    // Main Content
    .page
        flex-grow: 1 // width 100% : parent flex direction row
        min-width: 0
        main
            height: calc(100% - #{$header_height})

    &.unscrollable
        overflow: hidden
        height: 100vh

    img.picto
        display: inline-block
        height: 1em
        vertical-align: text-top
        margin-right: 5px

    .bordered
        border: 1px solid $light-grey
        border-radius: 10px
        margin-top: 15px
        margin-bottom: 15px
        padding: 15px
        overflow: hidden
        .btn-group
            display: flex
            .btn
                flex-grow: 1
            margin-top: -15px
            margin-left: -15px
            margin-right: -15px
            &:last-child
                margin-bottom: -15px
            .btn:first-child
                border-bottom-left-radius: 0
            .btn:last-child
                border-bottom-right-radius: 0

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app
        display: block
        main
            height: auto
            h3
                font-size: 20px
        .standfirst
            padding-left: 15px
        blockquote
            font-size: 14px
