.my-coownerships 
    .coownership-list
        padding-top: 30px 0 0 15px
        background-color: transparent
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr))
        height: auto

        .coownership-teaser 
            grid-area: inherit
            position: relative
            display: flex
            flex-direction: column
            align-items: flex-start
            padding: 25px 25px 15px 25px
            border-radius: 10px
            color: white
            // background-color: $grey
            background-color: $second-blue
            // &.pro
            //     background-color: $blue
            // &.ben
            //     background-color: $green
            
            h2
                font-size: 22px
                text-transform: uppercase
                a
                    color: white
                    &:hover,
                    &:focus,
                    &:active
                        color: white
                        text-decoration: none

            .coownership_nb_lot
                display: flex
                flex-grow: 1
                width: 100%
                p
                    min-width: 0
                    margin-bottom: 5px
                span
                    min-width: 0
                    margin-left: auto

            &.grey
                background-color: $grey

    .modal
        font-size: 24px
        .modal-scrollbar
            padding: 40px 40px 0
            h2
                text-align: center
                font-size: 26px
                margin-bottom: 60px
            p
                text-align: center
                margin-bottom: 60px

            .form
                font-size: 24px
                max-width: 100%
                .form-item
                    text-align: center
                    justify-content: center
                    margin-bottom: 40px
                .submit-button-wrapper
                    padding-top: 0
                    padding-bottom: 15px

    @media screen and (max-width: $screen-sm), screen and (max-height: $screen-sm) 
        .modal
            p
                font-size: 16px