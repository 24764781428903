.my-coownership
    &.grid.grid-6
        @media screen and (min-width: 1531px)
            grid-template-rows: 1fr auto 1fr auto
            grid-template-areas: "child1 child2" "child3 child3" "child4 child5" "child6 child6"
        @media screen and (max-width: 1530px) and (min-width: ($screen-lg + $aside_width))
            grid-template-areas: "child1 child1" "child2 child2" "child3 child3" "child4 child4" "child5 child6"
        .card
            max-height: 530px
    .card
        .card-body .standfirst
            margin-bottom: 0
            padding-right: 0 15px
            text-align: center
            .item-selector
                margin-right: 0
                margin-left: 15px
                margin-bottom: 0
                &:first-child
                    margin-left: 0

        .table-works tbody tr
            td:last-child
                white-space: nowrap

    .budget-card
        position: static
        .card-body
            position: relative
        .graph-circle
            padding-bottom: 0
            margin-bottom: 15px
        .caption
            position: absolute
            right: 0
            left: calc(50% + 150px)
            top: 50%
            transform: translateY(-50%)
            text-align: center
            font-size: 24px
            font-weight: $medium
            span
                display: block
            @media screen and (max-width: $screen-md), screen and (max-width: 1920px) and (min-width: 1530px)
                position: static
                transform: translateY(0)
                max-width: 100%
                margin-bottom: 15px
                span
                    display: inline
            @media screen and ( max-width: $screen-sm )
                font-size: 18px

        #budget-modal .modal
            height: auto
            width: auto
            border-color: $blue
            .modal-scrollbar
                padding: 30px 30px
                h2
                    font-weight: $bold
                    text-align: center
                    margin-bottom: 30px
                .modal-caption
                    text-align: center
                    font-style: italic
                    margin-bottom: 0

    .exercices .card-body
        padding-top: 5px
        .item-selector
            margin-top: 10px

    .tenant-charges .card-body
        position: relative
        .tutorial-bubble
            cursor: pointer
            position: absolute
            // for animation
            background: white no-repeat right -1px top
            background-origin: border-box
            background-image: none
            background-size: 40px
            border: 3px solid $light_grey
            border-radius: 20px
            right: 15px
            top: 15px
            max-width: 330px
            max-height: 170px
            text-align: justify
            transition: max-width 0.3s, max-height 0.3s, border-color 0.3s
            overflow: hidden
            box-shadow: -1px 2px 5px 1px rgba(0,0,0,0.3)
            .inner-bubble
                padding: 10px
                margin-bottom: 0
            &.hidden
                max-width: 40px
                max-height: 40px
                color: transparent
                background-image: url('../../images/pictogrammes/help-no-circle.svg')
                transition: max-width 0.1s, max-height 0.1s, border-color 0.3s, background-image 0.3s
                box-shadow: 0 0 0 0 transparent
            &:hover,
            &:focus,
            &:active
                // border-color: $grey
                border-color: $blue
                &.hidden
                    // background-image: url('../../images/pictogrammes/help-no-circle-grey.svg')
                    background-image: url('../../images/pictogrammes/help-no-circle-blue.svg')
            @media screen and (max-width: $screen-xs)
                display: none

.state .standfirst
    text-align: right
    padding: 0 15px
    @media screen and (min-width: $screen-md)
        padding-right: 50px

.state.tenant-charges .standfirst
    text-align: left
    padding: 15px
    margin-bottom: 0

    .dates-wrapper
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: flex-start

    .item-selector
        align-self: center
        margin-bottom: 20px

    .divider
        margin-bottom: 20px
        margin-right: 20px

    form
        // max-width: 400px
        width: auto
        display: flex
        flex-grow: 1
        flex-wrap: wrap
        align-items: center
        justify-content: flex-start
        margin-bottom: 8px
        &.form-multi-dropdown
            align-items: flex-start
            @media screen and (min-width: 1400px)
                flex-wrap: nowrap

    .fake-label,
    label
        display: inline-block
        margin-right: 5px
        font-size: 18px

    .form-item-list
        margin-right: 30px
        .fake-label,
        label
            min-width: 125px

    fieldset
        margin-bottom: 12px
        input
            padding: 3px 0 2px 7px
            margin-right: 10px
            &:last-child
                margin-right: 0
        .day,
        .month
            width: 60px
        .year
            width: 90px

    .submit-button-wrapper
        padding-top: 0
        margin-bottom: 12px
        text-align: left
        // padding-left: 150px


.table.two-dimensions-table tbody,
.table.table-tenant-charges-state tbody,
.table.table-exercice-state tbody
    .title,
    .subtitle
        text-align: center
        td
            padding: 10px
    .subtotal
        text-align: center

    .negative
        // For charges
        color: $red !important

.state.exercices
    .details-btn
        display: block
        float: left
        margin-right: 15px
    @media screen and (max-width: $screen-md)
        .standfirst
            text-align: left

.table.table-exercice-state
    tbody
        border-bottom: 2px solid $light_grey
        .title
            border-bottom: 2px solid $light_grey
            td
                padding-top: 20px
                padding-bottom: 20px
        a.table-picto
            background: url('../../images/pictogrammes/invoice-grey.svg') no-repeat center center
            background-size: contain
            &:hover,
            &:focus,
            &:active
                background-image: url('../../images/pictogrammes/invoice-blue.svg')
    .hideable td
        transition: all 0.3s
        .animated-cell
            overflow: hidden
    &.hidden
        .hideable td
            padding: 0
            .animated-cell
                height: 0
                padding: 0

.table.table-works tbody
    .animated-cell
        .table-picto
            height: 40px
            width: 34px
            padding: 5px 0

// .app.pro
//     .my-coownership
//         .tenant-charges .card-body .tutorial-bubble
//             &:hover,
//             &:focus,
//             &:active
//                 border-color: $blue
//                 &.hidden
//                     background-image: url('../../images/pictogrammes/help-no-circle-blue.svg')
//         #budget-modal .modal
//             border-color: $blue

//     .table.table-exercice-state  tbody
//         a.table-picto:hover,
//         a.table-picto:focus,
//         a.table-picto:active
//             background-image: url('../../images/pictogrammes/invoice-blue.svg')

// .app.ben
//     .my-coownership
//         .tenant-charges .card-body .tutorial-bubble
//             &:hover,
//             &:focus,
//             &:active
//                 border-color: $green
//                 &.hidden
//                     background-image: url('../../images/pictogrammes/help-no-circle-green.svg')
//         #budget-modal .modal
//             border-color: $green

//     .table.table-exercice-state  tbody
//         a.table-picto:hover,
//         a.table-picto:focus,
//         a.table-picto:active
//             background-image: url('../../images/pictogrammes/invoice-blue.svg')
