// My Account
.app
    .my-account
        &.grid.grid-7
            // grid-template-areas: "child1 child2" "child3 child3" "child4 child5" "child6 child7"
            grid-template-columns: 1fr 1fr
            grid-template-rows: 1fr auto auto 
            @media screen and (max-width: 1310px)
                // grid-template-areas: "child1" "child2" "child3" "child4" "child5" "child6" "child7"
                grid-template-columns: 1fr
                grid-template-rows: 1fr 1fr auto auto auto auto 

            .my-account-others
                display: flex
                flex-direction: column
                justify-content: center
                .btn-wrapper
                    margin-bottom: 20px
                    &:last-child
                        margin-bottom: 0

            .table.table-advances
                td:first-child
                    text-align: left

        .sliced-card
            border: 0
            background-color: transparent
            min-height: 150px // loading
            &.sliced-card-graph
                min-height: calc((100vh - #{$header_height} - 45px) /2)
            .card-header
                border: 2px solid $light_grey
            .card-bodys-wrapper
                display: flex
                flex-direction: row
                justify-content: space-between
                transition: all 0.3s
                overflow: hidden
                flex-grow: 1
                .half-card
                    border: 2px solid $light_grey
                    border-top: 0
                    border-radius: 0 0 10px 10px
                    background-color: white
                    width: calc(50% - 10px)
                    // due to flex
                    max-width: calc(50% - 10px)
                    flex-grow: 1
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    transition: all 0.3s
                    #soldeGraph
                        margin-bottom: 0

    // My Coowner Account
    .my-coowner-account
        .standfirst h3
            display: inline-block
            margin-right: 75px
            margin-bottom: 15px

        .table-account 
            .address
                max-width: 320px
            a.table-picto
                background: url('../../images/pictogrammes/invoice-grey.svg') no-repeat center center
                background-size: contain

    // My Reminders
    .table.table-reminders
        .picto-table-wrapper,
        .loading
            display: inline-block
            vertical-align: middle
        .table-picto
            height: 40px
            width: 34px
            transition: all 0.3s
            margin: 0 10px
            padding: 3px 2px
            border-radius: 5px

@media screen and ( max-width: $screen-xl ) and ( min-width: $screen-lg ), screen and ( max-width: 900px )
    .app .my-account .sliced-card .card-bodys-wrapper
        display: block
        .half-card
            display: block
            width: 100%
            max-width: 100%
            &:first-child
                border-radius: 0

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .my-account .sliced-card .card-bodys-wrapper .half-card
        border-radius: 0
        border-bottom: 0
        padding-top: 0
        &:first-child
            padding-top: 15px
        &:last-child > :last-child
            padding-bottom: 15px

    .app .my-coowner-account .standfirst h3
        font-size: 20px