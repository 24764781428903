.app aside
    width: $aside_width
    min-width: $aside_width // due to flex
    color: $grey
    font-size: 25px

    .menu-burger
        display: none

    .aside-content-wrapper
        height: 100%
        display: flex
        flex-direction: column
        align-items: center
        // Edge fallback
        overflow: auto
        overflow: hidden auto
        padding-top: $header_height
        position: fixed
        width: $aside_width // due to fixed
        min-width: $aside_width
        // background-color: $light_grey
        background-color: $blue
        color: white

        .profile-img-wrapper
            padding: 0 15px
            .profile-img
                width: 165px
                height: 165px
                margin-bottom: 50px
                border-radius: 50%
            div.profile-img
                background-color: white
                display: flex
                justify-content: center
                align-items: center

        nav
            flex-grow: 1
            min-height: 1px
            width: 100%
            margin-bottom: 50px
            ul
                margin: 0
                padding: 0
                li
                    list-style-type: none
            a
                position: relative
                padding: 0 50px
                display: block
                text-decoration: none
                transition: all 0.3s
                overflow: hidden
                z-index: 1
                // color: $grey
                color: white
            a:before
                content: ""
                display: block
                position: absolute
                top: 0
                left: 0
                right: 0
                bottom: 0
                transform: translateX(-101%)
                transition: all 0.3s
                z-index: -1
                // background-color: $grey
                background-color: white
            a:hover,
            a:focus,
            a:active,
            a.active
                text-decoration: none
                // color: white
                // background-color: transparentize($grey, 0.7)
                color: $blue
                background-color: transparentize(white, 0.7)
                &:before
                    transform: translateX(0)

            .contact-link
                display: none

            .my-coownership-link
                position: relative
                margin-top: 10px
                padding-top: 10px
                &:before
                    content: ''
                    display: block
                    position: absolute
                    top: 0
                    left: 30px
                    right: 30px
                    height: 2px
                    background-color: white

        .aside-bottom
            padding: 0 40px 20px
            .img-fluid
                display: block
                margin: 0 auto
            .aside-playstore-img
                display: none
            .aside-tutorial-img
                width: 55px
                margin-bottom: 50px
            .aside-application-logo
                width: 110px
                &.img-grey
                    width: 100px

// .app.pro aside .aside-content-wrapper
//     background-color: $blue
//     color: white
//     nav a
//         color: white
//         &:before
//             background-color: white
//         &:hover,
//         &:focus,
//         &:active,
//         &.active
//             color: $blue
//             background-color: transparentize(white, 0.7)

// .app.ben aside .aside-content-wrapper
//     background-color: $green
//     color: white
//     nav a
//         color: white
//         &:before
//             background-color: white
//         &:hover,
//         &:focus,
//         &:active,
//         &.active
//             color: $green
//             background-color: transparentize(white, 0.7)

@media screen and (max-width: $screen-lg)
    .app aside
        transition: all 0.3s
        position: absolute
        height: 100vh
        top: 0
        left: 0
        z-index: 998
        transform: translateX(-#{$aside_width})
        &:before
            content: ''
            display: block
            position: absolute
            left: 0
            top: 0
            width: 0
            height: 100vh
            background-color: transparentize($grey, 0.5)
            z-index: -1
            opacity: 0
            transition: all 0.3s

        .menu-burger
            display: flex
            justify-content: space-around
            align-items: center
            flex-direction: column
            position: absolute
            z-index: 1
            top: 0
            left: $aside_width
            background-color: transparent
            border: 0
            border-radius: 0
            margin: 0
            padding: 10px
            height: $header_height
            width: $header_height
            opacity: 1
            transition: all 0.3s
            .menu-burger-slice
                display: block
                position: relative
                height: 4px
                width: 43px
                border-radius: 2px
                opacity: 1
                transform-origin: left center
                transform: rotate(0)
                transition: .25s ease-in-out
                // background-color: $grey
                background-color: white

        &.in
            transform: translateX(0)
            box-shadow: 2px 0 4px 0 rgba(0,0,0,0.3)
            &:before
                width: 100vw
                opacity: 1
                transition: opacity 0.3s

            .menu-burger
                left: calc(#{$aside_width} - #{$header_height})
                .menu-burger-slice:nth-child(1)
                    transform: rotate(45deg)
                    top: 2px
                    left: 7px
                .menu-burger-slice:nth-child(2)
                    width: 0
                    opacity: 0
                .menu-burger-slice:nth-child(3)
                    transform: rotate(-45deg)
                    bottom: 1px
                    left: 7px

            .aside-content-wrapper
                // Edge fallback
                overflow: auto
                overflow: hidden auto
                scrollbar-color: transparentize(white, 0.1) darken($green, 7)
                scrollbar-width: thin
                &::-webkit-scrollbar
                    background-color: darken($green, 7)
                    width: 7px
                &::-webkit-scrollbar-thumb
                    background-color: transparentize(white, 0.1)
                    -webkit-border-radius: 1ex
                    border-radius: 4px

        .aside-content-wrapper
            align-items: flex-start
            justify-content: center
            flex-flow: row wrap
            padding-top: 50px
            position: static
            nav .contact-link
                display: block

            .aside-bottom
                align-self: flex-end
                .aside-playstore-img
                    display: block
                    width: 55px
                    margin-bottom: 20px

    // .app.pro aside .menu-burger .menu-burger-slice
    //     background-color: white

    // .app.ben aside .menu-burger .menu-burger-slice
    //     background-color: white

@media screen and (max-width: $screen-sm), screen and (max-height: $aside_height)
    .app aside
        font-size: 18px
        width: $aside_width_responsive
        min-width: $aside_width_responsive // due to flex

        &.in .menu-burger
            left: calc(#{$aside_width_responsive} - #{$header_height})

        .aside-content-wrapper
            width: $aside_width_responsive // due to fixed
            min-width: $aside_width_responsive

            .profile-img-wrapper .profile-img
                width: 100px
                height: 100px
                margin-bottom: 25px

            nav
                margin-bottom: 25px
                a
                    padding: 0 25px

            .aside-bottom 
                .aside-playstore-img
                    width: 40px
                    margin-bottom: 15px
                .aside-tutorial-img
                    width: 40px
                    margin-bottom: 25px

@media screen and (max-height: 575px)
    .app aside .aside-content-wrapper
        position: static
        width: 100%
        min-width: 1px
        nav
            flex-grow: 0