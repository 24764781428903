// Pagination
.app .pagination 
    margin: 0
    padding: 15px
    justify-content: center
    li
        margin: 0 7px
        &:first-child
            margin-left: 0
        &:last-child
            margin-right: 0

    .pager
        @extend .btn
        padding: 3px 10px 0
        border-radius: 3px
        // border: 2px solid $grey
        // color: $grey
        color: darken($blue, 15)
        border: 2px solid darken($blue, 15)
        &.active
            color: white
            // border: 2px solid darken($grey, 15)
            border: 2px solid darken($blue, 25)
            &:before
                // background-color: darken($grey, 15)
                background-color: darken($blue, 25)
        &:after
            content: none

// .app.pro .pagination .pager
//     color: darken($blue, 15)
//     border: 2px solid darken($blue, 15)
//     &.active
//         color: white
//         border: 2px solid darken($blue, 25)
//         &:before
//             background-color: darken($blue, 25)

// .app.ben .pagination .pager,
//     color: darken($green, 15)
//     border: 2px solid darken($green, 15)
//     &.active
//         color: white
//         border: 2px solid darken($green, 25)
//         &:before
//             background-color: darken($green, 25)