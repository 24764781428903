.app .my-info .card
    font-size: 22px

    .card-header
        border-bottom: 0

    .btn-menu
        border-radius: 10px
        overflow: hidden
        line-height: 1
        .btn:not(:first-child)
            margin-left: -18px
            padding-left: 45px

    .my-info-form
        padding-bottom: 25px
        label
            width: 140px
        .fake-label
            font-weight: $medium
        .cgu
            margin-bottom: 20px
            padding-top: 10px
            a
                padding-right: 0

        .form-item-file-wrapper
            .fake-label
                width: 140px

    .my-info-password
        .form-item-list
            .form-item label
                width: 270px
                margin-bottom: 0

@media screen and (max-width: $screen-xs)
    .app .my-info .card 
        .btn-menu
            display: inline-block
            border-radius: 0
            background-color: transparent
            margin-bottom: -10px
            .btn
                margin-right: 15px
                margin-bottom: 10px
            .btn:not(:first-child)
                margin-left: 0
                padding-left: 35px

        .my-info-form 
            .table
                margin-bottom: 25px

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .my-info .card 
        font-size: 14px
        .my-info-form  .form-item-file-wrapper .fake-label
            font-size: 18px 