.app .capital-calls
    .table.table-capital-calls    
        .table-picto
            height: 40px
            width: 35px
        .total-capital-calls
            font-weight: $bold
            background-color: $light-grey
            color: $blue

// .app.pro .table
//     .total-capital-calls
//         color: $blue

// .app.ben .table
//     .total-capital-calls
//         color: $green
