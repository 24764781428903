.app .simulation .form 
    padding: 15px
    .fake-label
        display: inline-block
        font-weight: $medium
        padding-right: 15px
        min-width: 0
        width: 240px
    label
        width: 240px
        em
            font-size: 18px
            font-weight: $regular
    
    fieldset
        margin-bottom: 12px
        label
            width: 0
        input
            padding: 3px 0 2px 7px
            margin-right: 10px
            &:last-child
                margin-right: 0
        #day,
        #month
            width: 55px
        #year
            width: 85px

    select
        display: inline-block
        border: 2px solid $light_grey
        border-radius: 13px
        line-height: 1
        color: $grey
        padding: 3px 10px 0
        width: 400px
        max-width: 100%
        background-color: white
    
    .form-item
        display: block
        input
            padding-top: 3px
        #amount
            width: 400px
            max-width: 100%

    .submit-button-wrapper
        padding-top: 30px
        margin-bottom: 50px

@media screen and (max-width: $screen-sm)
    .app .simulation .form 
        padding: 0
