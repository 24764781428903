.loader
    display: block
    position: relative
    width: 38px
    height: 38px
    margin: 0 auto
    padding-bottom: 15px
    &:before
        background-color: red
    div
        box-sizing: border-box
        display: block
        position: absolute
        width: 30px
        height: 30px
        margin: 4px
        border: 4px solid #fff
        border-radius: 50%
        animation: loading 1.2s cubic-bezier(0.2, 0.45, 0.7, 0.2) infinite
        // border-color: $grey transparent transparent transparent
        border-color: $blue transparent transparent transparent

        &:nth-child(1)
            animation-delay: -0.05s
            // border: 4px solid $light-grey
            border: 4px solid transparentize($blue, 0.7)

        &:nth-child(2)
            animation-delay: -0.1s

        &:nth-child(3)
            animation-delay: -0.15s

// .ben
//     .loader div
//         border-color: $green transparent transparent transparent
//         &:first-child
//             border: 4px solid transparentize($green, 0.7)

// .pro
//     .loader div
//         border-color: $blue transparent transparent transparent
//         &:first-child
//             border: 4px solid transparentize($blue, 0.7)

.loading
    position: relative
    pointer-events: none
    transition: all 0s
    overflow: hidden
    &::before
        position: absolute
        content: ''
        top: 0
        left: 0
        background: rgba(255,255,255,.8)
        width: 100%
        height: 100%
        border-radius: 5px
        z-index: 10
    &::after
        position: absolute
        content: ''
        top: 50%
        left: 50%
        margin: -1em 0 0 -1em
        width: 2em
        height: 2em
        -webkit-animation: loading .6s linear
        animation: loading .6s linear
        -webkit-animation-iteration-count: infinite
        animation-iteration-count: infinite
        border-radius: 100%
        border-color: #767676 rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.1)
        border-style: solid
        border-width: 0.2em
        -webkit-box-shadow: 0 0 0 1px transparent
        box-shadow: 0 0 0 1px transparent
        z-index: 11

@keyframes loading
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)