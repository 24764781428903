.app
    .form
        font-size: 22px
        width: 750px
        max-width: 100%
        .form-item
            display: flex
            margin-bottom: 12px

        label
            display: inline-block
            font-weight: $medium
            padding-right: 15px
            min-width: 0

        input:-ms-input-placeholder, /* I.E. */
        input::-ms-input-placeholder, /* Microsoft Edge */
        input::placeholder
            color: $light_grey
            opacity: 1

        .form-item-list
            input,
            textarea
                display: inline-block
                flex-grow: 1
                min-width: 0
                border: 2px solid $light_grey
                border-radius: 13px
                line-height: 1
                color: $grey
                padding: 0 10px 0
            textarea
                height: 150px

        .form-item-radio input
            margin-right: 5px
        .form-item-list .form-item-check-box
            display: none
        .form-item-check-box-label
            padding-left: 1.8em
            position: relative
            &:before,
            &:after
                display: block
                position: absolute
                top: 50%
                transform: translateY(-50%)
            &:before
                content: ""
                left: 0
                height: 1.3636em
                width: 1.3636em
                border: 2px solid $grey
            &:after
                content: url('../../images/pictogrammes/checked-grey.svg')
                left: 3px
                height: 1.1em
                width: 1.1em
                transition: all 0.3s
                opacity: 0
            &.checked:after
                opacity: 1
        .form-item-check-box:checked+.form-item-check-box-label:after
            opacity: 1

        .form-item-file-wrapper
            display: flex
            .fake-label
                display: inline-block
                width: 140px
            .form-item-file
                flex-grow: 1
                min-width: 215px
                border: 1px solid $light_grey
                border-radius: 5px
                padding: 15px 20px 5px
                font-size: 16px
                .form-group
                    margin: 0
                label.btn
                    margin-top: 10px
                    padding-right: 35px
                    width: auto
                    &.checked:after
                        background-image: url("../../images/pictogrammes/checked-white.svg")
                        background-repeat: no-repeat
                        background-position: right 10px center
                        background-size: auto 16px

                .input-file
                    display: none

    .submit-button-wrapper
        text-align: center
        padding-top: 70px

.form-group-check-box
    input,
    label
        cursor: pointer
    &:not(.form-item-radio) 
        position: relative
        input
            position: absolute
            z-index: -1
            top: 0.5em
            left: 0.25em

.modal .form-group-check-box
    white-space: nowrap
    .form-item-check-box-label
        padding-left: 1.8em
        position: relative
        margin: 0 auto

.form-item-radio.form-group-check-box 
    white-space: nowrap
    input
        margin-right: 5px

@media screen and (max-height: $screen-md)
    .app .submit-button-wrapper
        padding-top: 25px
        
@media screen and (max-width: $screen-md)
    .app .form .form-item-file-wrapper
        display: block
        .fake-label
            margin-bottom: 5px

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .form
        font-size: 18px
        .form-item
            display: block
            input,
            textarea
                display: block
                width: 100%
                padding: 5px 10px