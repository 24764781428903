// Variables
// =========
// screen size for media queries
$screen-xs: 448px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
// color
$grey: #707070;
$light_grey: #cecece;
$pale_grey: #f1f0f1;
$green: #1c9739;
$blue: #336699;
$second-blue: #458cd4;
$yellow: #FFB93C;
$orange: #ff9913;
$red: #c73636;
// font weight
$light: 300;
$regular: 400;
$medium: 500;
$bold: 600;
$black: 700;
// font familly
$font-family-base: 'mavenpro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-futura: 'futura', 'Helvetica Neue', Helvetica, Arial, sans-serif;
// size
$header_height: 70px;
$aside_height: 880px;
$aside_width: 300px;
$aside_width_responsive: 200px;

// Import
// ======
// font
@import "sass/font";
// components
@import "sass/app";
@import "sass/grid";
@import "sass/card";
@import "sass/components/header";
@import "sass/components/aside";
@import "sass/components/form";
@import "sass/components/table";
@import "sass/components/modal";
@import "sass/components/graph";
@import "sass/components/btn";
@import "sass/components/pagination";
@import "sass/components/loading";
@import "sass/components/edge_message";
// containers
@import "sass/containers/login";
@import "sass/containers/my_coownerships";
@import "sass/containers/my_info";
@import "sass/containers/dashboard";
@import "sass/containers/my_data";
@import "sass/containers/incidents";
@import "sass/containers/my_account";
@import "sass/containers/my_coownership";
@import "sass/containers/my_files";
@import "sass/containers/my_judicials";
@import "sass/containers/coownership_council";
@import "sass/containers/bank";
@import "sass/containers/balance";
@import "sass/containers/great_book";
@import "sass/containers/capital_calls";
@import "sass/containers/simulation";
@import "sass/containers/contact";
@import "sass/containers/legal_notice";
