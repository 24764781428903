.app .grid.login
    display: flex
    justify-content: center
    align-items: flex-start
    .card
        margin-top: 125px
        height: auto
        width: 800px // modal width
        max-width: 100%
        min-height: 200px
        .card-body
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column

            p
                font-size: 15px

            .form
                padding-top: 15px
                min-width: 0
                label
                    width: 170px
                .submit-button-wrapper
                    padding-top: 30px
                    padding-bottom: 15px
            
            .forgotten-password
                align-self: flex-start
                padding-top: 15px

@media screen and ( max-height: 770px)
    .app .grid.login
        align-items: center
        .card
            margin-top: 0

@media screen and ( max-height: $screen-sm) and (max-width: $screen-lg)
    .app .grid.login
        width: 100%
        .card
            height: 100vh
            margin-top: 0
            width: 100%
            .card-body 
                justify-content: start

@media screen and ( max-height: 480px) //modal height + padding
    .app .grid.login .card .submit-button-wrapper
        padding-top: 25px

@media screen and ( max-height: $screen-xs) and (max-width: $screen-lg)
    .app .grid.login .card .card-body .form
        width: 325px

@media screen and ( max-width: $screen-sm)
    .app .grid.login .card
        height: 100vh
        margin-top: 0
        .card-body 
            justify-content: start
            .form
                width: 325px
                max-width: 100%
                .form-item
                    flex-direction: column
                    input
                        line-height: inherit