.app .dashboard
    &.grid.grid-2
        grid-template-areas: "child1 child2" "child3 child3"
        grid-template-columns: 1fr 1.5fr
        grid-template-rows: 1fr 1fr
        &.parent-graph
            grid-template-rows: 1fr auto
        @media screen and (max-width: 1700px)
            grid-template-columns: 1fr 1fr
        @media screen and (max-width: 1450px)
            grid-template-areas: "child1" "child2" "child3"
            grid-template-columns: 1fr
            grid-template-rows: auto 1fr 1fr
            &.parent-graph
                grid-template-rows: auto 1fr auto
    // temporary override with news band
    &.grid.grid-2
        grid-template-areas: "child1 child2" "child3 child3" "child4 child4"
        grid-template-rows: 1fr auto 1fr
        &.parent-graph
            grid-template-rows: 1fr auto auto
        @media screen and (max-width: 1450px)
            grid-template-areas: "child1" "child2" "child3" "child4"
            grid-template-rows: auto 1fr auto 1fr
            &.parent-graph
                grid-template-rows: auto 1fr auto auto

    .notification
        position: relative
        padding-left: 30px
        &:before
            content: ""
            display: block
            position: absolute
            top: 5px
            left: 0
            height: 15px
            width: 15px
            border-radius: 50%
            // background-color: $grey
            background-color: $blue

        h2
            margin-bottom: 0
            font-weight: $medium
            font-size: 20px
            text-transform: uppercase

        &:last-child p
            margin-bottom: 0

// .app.pro .dashboard .notification:before
//     background-color: $blue

// .app.ben .dashboard .notification:before
//     background-color: $green

@media screen and (max-width: $screen-sm)
    .app .dashboard .notification h2
        font-size: 18px

@media screen and (max-height: $screen-sm)
    .app .dashboard .notification h2
        font-size: 18px

@media screen and (max-width: $screen-xs)
    .app .dashboard
        .notification
            padding-left: 20px
            &::before
                left: -5px
                width: 12px
                height: 12px