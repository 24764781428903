.app .my-judicials 
    .doc-anchor
        cursor: pointer
        text-decoration: underline
        &:hover,
        &:focus,
        &:active
            // color: black
            color: $blue
    &.grid-3
        grid-template-rows: auto 1fr 1fr
        @media (min-height: 800px) 
            .card
                max-height: 45vh

.app .my-correspondence-vote 
    .table-resolutions
        border-bottom: 1px solid $light-grey
        .td-label
            max-width: 450px
    .doc-anchor
        display: inline-block
        cursor: pointer
        text-decoration: underline
        padding: 20px 0 10px
        .picto-file
            height: 25px
            vertical-align: text-bottom
            padding-right: 5px
        &:hover,
        &:focus,
        &:active
            // color: black
            color: $blue

    a.table-picto
        width: 25px
        background: url('../../images/pictogrammes/pdf-grey.svg') no-repeat center center
        background-size: contain
        &:hover,
        &:focus,
        &:active
            background-image: url('../../images/pictogrammes/pdf-blue.svg')

    .form
        width: 100%
        font-size: 16px
        .fake-label
            padding-right: 10px
            width: 25%
            min-width: 300px

    .form-item-file h3
        font-size: 1.5em

// .app.pro
//     .my-judicials 
//         .doc-anchor:hover,
//         .doc-anchor:focus,
//         .doc-anchor:active
//             color: $blue

// .app.ben
//     .my-judicials 
//         .doc-anchor:hover,
//         .doc-anchor:focus,
//         .doc-anchor:active
//             color: $green
            