.app .grid
    display: grid
    grid-gap: 15px
    height: 100%
    padding: 15px
    background-color: $pale_grey
    >:nth-child(1)
        grid-area: child1
    >:nth-child(2)
        grid-area: child2
    >:nth-child(3)
        grid-area: child3
    >:nth-child(4)
        grid-area: child4
    >:nth-child(5)
        grid-area: child5
    >:nth-child(6)
        grid-area: child6
    .card
        min-height: 275px
        &.card-btn
            min-height: 0

    &.grid-1
        grid-template-areas: "child1"
        grid-template-columns: 1fr
        grid-template-rows: 1fr
        .card
            min-height: 1px
    &.grid-2
        grid-template-areas: "child1" "child2"
        grid-template-columns: 1fr
        grid-template-rows: 1fr 1fr
        .card
            max-height: 50vh
    &.grid-3
        grid-template-areas: "child1" "child2" "child3"
        grid-template-columns: 1fr
        grid-template-rows: 1fr 1fr 1fr
        .card
            max-height: 33vh
    &.grid-4
        grid-template-areas: "child1 child2" "child3 child4"
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr 1fr
        .card
            max-height: 50vh
    &.grid-5
        grid-template-areas: "child1 child2" "child3 child3" "child4 child5" "child6 child6"
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr 1fr 1fr
        .card
            max-height: 33vh
    &.grid-6
        grid-template-areas: "child1 child2" "child3 child4" "child5 child6"
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr 1fr 1fr
        .card
            max-height: 33vh
    &.grid-7
        grid-template-areas: "child1 child2" "child3 child3" "child4 child5" "child6 child7"
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr 1fr 1fr 1fr
        .card
            max-height: 33vh
    
@media screen and (max-width: ($screen-lg + $aside_width))
    .app .grid.grid-4
        grid-template-areas: "child1" "child2" "child3" "child4"
        grid-template-columns: 1fr
        grid-template-rows: auto
    .app .grid.grid-5
        grid-template-areas: "child1" "child2" "child3" "child4" "child5"
        grid-template-columns: 1fr
        grid-template-rows: auto
    .app .grid.grid-6
        grid-template-areas: "child1" "child2" "child3" "child4" "child5" "child6"
        grid-template-columns: 1fr
        grid-template-rows: auto
    .app .grid.grid-7
        grid-template-areas: "child1" "child2" "child3" "child4" "child5" "child6" "child7"
        grid-template-columns: 1fr
        grid-template-rows: auto

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-sm)
    .app .grid
        &.grid-3 .card,
        &.grid-5 .card,
        &.grid-6 .card,
        &.grid-7 .card
            max-height: 50vh

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-sm) and (max-width: $screen-lg)
    .app .grid
        display: block
        padding: 0
        min-height: 100%
        background-color: white
        &.grid-1
            display: grid
        &.grid-2 .card,
        &.grid-3 .card,
        &.grid-4 .card,
        &.grid-5 .card,
        &.grid-6 .card,
        &.grid-7 .card
            min-height: 150px

@media screen and (max-width: $screen-xs), screen and (max-height: $screen-xs)
    .app .grid
        &.grid-2 .card,
        &.grid-3 .card,
        &.grid-4 .card,
        &.grid-5 .card,
        &.grid-6 .card,
        &.grid-7 .card
            max-height: 100vh